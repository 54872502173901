<template>
  <div v-if="payments" class="px-4">
    <v-slide-group
      class="p-0 m-0 pt-3"
      :style="$root.mobile ? 'min-width: 96vw;' : ''"
    >
      <v-slide-item>
        <div
          class="
            userprofile_box
            white--text
            listitem
            p-4
            py-4
            primary
            lighten-1
            pushxl
          "
          :style="
            $root.mobile ? 'width: 55vw;min-width: 55vw;' : 'min-width:270px'
          "
        >
          <div class="inner pushxl">
            <unicon
              name="pound-circle"
              fill="#FFFFFF"
              height="20"
              class="pushx"
            ></unicon
            ><span>{{ $t("PROFILE.payment.total") }}</span>
          </div>
          <v-spacer /><span class="font-weight-black text-h5"
            >£{{ totalpaid }}</span
          >
        </div>
      </v-slide-item>
      <v-slide-item>
        <div
          class="
            userprofile_box
            white--text
            listitem
            p-4
            py-4
            teal
            lighten-1
            pushxl
          "
          :style="
            $root.mobile ? 'width: 55vw;min-width: 55vw;' : 'min-width:270px'
          "
        >
          <div class="inner pushxl">
            <unicon
              name="check-circle"
              fill="#FFFFFF"
              height="20"
              class="pushx"
            ></unicon
            ><span>{{ $t("PROFILE.payment.received") }}</span>
          </div>
          <v-spacer /><span class="font-weight-black text-h5"
            >£{{ totalrecevied }}</span
          >
        </div>
      </v-slide-item>
      <v-slide-item>
        <div
          class="
            userprofile_box
            white--text
            listitem
            p-4
            py-4
            amber
            lighten-1
            pushxl
          "
          :style="
            $root.mobile ? 'width: 55vw;min-width: 55vw;' : 'min-width:270px'
          "
        >
          <div class="inner pushxl">
            <unicon
              name="exclamation-circle"
              fill="#FFFFFF"
              height="20"
              class="pushx"
            ></unicon
            ><span>{{ $t("PROFILE.payment.pending") }}</span>
          </div>
          <v-spacer /><span class="font-weight-black text-h5"
            >£{{ totalpaid - totalrecevied }}</span
          >
        </div>
      </v-slide-item>
    </v-slide-group>

    <v-list>
      <template v-for="(item, i) in payments">
        <v-list-item class="listitem mb-2" :key="item.id">
          <v-list-item-avatar color="rgba(165, 203, 239, 0.20)">
            <v-icon size="20" color="primary">{{ item.method.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              style="
                font-weight: 500;
                font-size: 1.1rem;
                display: flex;
                align-items: center;
              "
            >
              <div
                :class="item.status == 'pending' ? 'yellow' : 'teal'"
                class="white--text darken-3 text-center p-1 px-3 rounded pushx"
                style="width: fit-content; font-size: 0.7rem"
              >
                {{ item.status }}
              </div>
              <div class="">
                <span class="text-primary text-weight-bold"
                  >#{{ item.udid }}</span
                >
                {{ $t("PROFILE.payment.via") }} {{ item.method.name }}
              </div>
            </v-list-item-title>
            <v-list-item-subtitle class="py-1">
              {{ $t("PROFILE.payment.on") }} {{ item.created_on }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.appointment.length > 0">
              <span v-if="item.appointment[0].type === 'video'">
                {{
                  $t("PROFILE.payment.forvide", {
                    username: `Dr ${item.appointment[0].doctor.doctor_id.first_name} ${item.appointment[0].doctor.doctor_id.last_name}`
                  })
                }}
              </span>
              <span v-else-if="item.appointment[0].type === 'clinic'">
                {{
                  $t("PROFILE.payment.forclinic", {
                    username: `Dr ${item.appointment[0].doctor.doctor_id.first_name} ${item.appointment[0].doctor.doctor_id.last_name}`
                  })
                }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: flex-end;
              "
            >
              <span style="font-size: 2.1rem" class="font-weight-bold"
                >£{{ item.amount }}</span
              >
            </div>
            <v-btn
              v-if="false"
              small
              fab
              elevation="0"
              color="rgba(239, 165, 179, 0.25)"
            >
              <unicon
                style="margin-right: auto; margin-left: auto"
                name="trash-alt"
                fill="#f64e60"
                height="20"
              ></unicon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider
          style="color: rgb(247 247 247)"
          class="p-0 m-0"
          v-if="i < payments.length - 1 && false"
          :key="'d' + i"
        />
      </template>
    </v-list>
  </div>
  <v-row align="center" justify="center" style="height: 10vh" v-else>
    <v-progress-circular color="primary" indeterminate />
  </v-row>
</template>
<script>
export default {
  name: "UserPayments",
  components: {},
  props: ["uid"],
  data() {
    return {
      doedit: true,
      payments: null
    };
  },
  beforeMount() {},
  activated() {
    if (this.payments == null) {
      this.loadPayments();
    }
  },
  deactivated() {
    this.payments = null;
  },
  mounted() {},
  methods: {
    async loadPayments() {
      await this.$payments.getPateintPayments(this.uid).then(data => {
        this.payments = data.data.data;
      });
    }
  },
  computed: {
    totalpaid() {
      let total = 0;
      if (this.payments) {
        this.payments.forEach(slip => {
          total += parseInt(slip.amount);
        });
      }
      return total;
    },
    totalrecevied() {
      let total = 0;
      if (this.payments) {
        this.payments.forEach(slip => {
          if (slip.status == "confirmed") total += parseInt(slip.amount);
        });
      }
      return total;
    }
  }
};
</script>
